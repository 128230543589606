import React from "react";
import Typed from "react-typed";
import coverPhoto from "../img/coverphoto.jpg";

class Intro extends React.Component {
  render() {
    return (
      <div id="home" className="intro route bg-image " style={{backgroundImage: "url(" + coverPhoto +")"}}>
        <div className="intro-content display-table">
          <div className="table-cell">
            <div className="container">
              <h1 className="intro-title mb-3">Kevin Hazelton</h1>
              <hr className="intro-divider mb-3" />
              <p className="intro-subtitle">
                Piano Instructor, Private In-Home Lessons
              </p>
              <p className="pt-3">
                <a
                  className="btn btn-outline-light btn js-scroll px-4"
                  style={{"borderRadius": "0"}}
                  href="#contact"
                  role="button"
                >
                  Contact Now
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
