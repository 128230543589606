import React from "react";
import imageOverlay from "../img/coverphoto.jpg";

class Contact extends React.Component {
  render() {
    return (
      <section
        className="paralax-mf footer-paralax bg-image route"
        style={{ backgroundImage: "url(" + imageOverlay + ")" }}
      >
        {/* <div className="overlay-mf"></div> */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div id="contact" className="box-shadow-full">
                <div className="row">
                  <div className="col-md-5 pt-4 pt-md-0">
                    <h5 className="title-left">Get in Touch</h5>
                  </div>
                </div>
                <div className="row">
                <div className="col-md-5">
                    <div className="more-info pt-4">
                      <p className="lead">
                        Currently accepting new students!
                        <br /><br />
                        For lesson inquiries or new student registration, please fill the form to send me a message.
                        <br /><br />
                        Feel free to simply send me an email or give me a call as well!
                      </p>
                      <br />
                      Kevin Hazelton, Piano Instructor<br />
                      Port Jefferson, NY 11777
                      <hr className="contact-div" />
                      <ul class="list-ico">
                        <li><span class="ion-ios-telephone"/> (518) 944-1414</li>
                        <li><span class="ion-email"/>kshazelton@gmail.com</li>
                        <li>
                          <span class="ion-social-youtube"/>
                          <a href="https://www.youtube.com/user/RockysNation" target="_blank">
                            UkuleleSolo
                          </a>
                        </li>
                      </ul>
                      <br />
                    </div>
                  </div>
                  <div className="col-md-7">
                    {/* <div className="title-box-2">
                      <h5 id="contact-form" className="title-left">Send Me a Message</h5>
                    </div>
                    <div>
                      <form
                        action=""
                        method="POST"
                        className="contactForm"
                      >
                        <div id="sendmessage">
                          Your message has been sent. Thank you!
                        </div>
                        <div id="errormessage"></div>
                        <div className="row">
                          <div className="col-md-12 mb-1">
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="name"
                                placeholder="Your Name*"
                                required
                              />
                              <div className="validation"></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-1">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Your Email*"
                                required
                              />
                              <div className="validation"></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-1">
                            <div className="form-group">
                              <input
                                type="text"
                                name="town"
                                className="form-control"
                                id="town"
                                placeholder="Town of Residence*"
                                required
                              />
                              <div className="validation"></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-1">
                            <div className="form-group">
                              <input
                                type="number" min="0"
                                name="student-count"
                                className="form-control"
                                id="student-count"
                                placeholder="Number of Students*"
                                required
                              />
                              <div className="validation"></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-1">
                            <div className="form-group">
                              <input
                                type="text"
                                name="student-age"
                                className="form-control"
                                id="student-age"
                                placeholder="Age(s) of Student(s)*"
                                required
                              />
                              <div className="validation"></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-1">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="prev-experience"
                                id="prev-experience"
                                rows="3"
                                placeholder="Previous Experience (any musical experience, on the piano or otherwise)"
                              ></textarea>
                              <div className="validation"></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-1">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="comments"
                                id="comments"
                                rows="3"
                                placeholder="Comments"
                              ></textarea>
                              <div className="validation"></div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              type="submit"
                              className="button button-a button-big" style={{"borderRadius": "0"}}
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </form>
                    </div> */}
                    <div>
                    <iframe
                      src="https://docs.google.com/forms/d/e/1FAIpQLSeDg8-t-BRFujgoCvEUwhExN-4I6jQCG9NEHNmmW6sTkD3V3Q/viewform?embedded=true"
                      width="100%" height="600"
                      frameborder="0" marginheight="0" marginwidth="0"
                      className="google-form"
                    >
                      Loading Form…
                    </iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-box">
                  <br /><br />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    );
  }
}

export default Contact;