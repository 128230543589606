import React from "react";
import imageOverlay from "../img/coverphoto.jpg";

class Tuition extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        { id: "HTML5_skill", content: "HTML5", porcentage: "80%", value: "80" },
        { id: "CSS3_skill", content: "CSS3", porcentage: "75%", value: "75" },
      ],
      tuition_rates: [
        {
          minutes: 30,
          cost: 200,
        },
        {
          minutes: 60,
          cost: 300,
        },
      ],
      additional_info: [
        {
          title: "Tuition",
          content:
            "Tuition is a monthly flat fee that includes weekly lessons for the month (3-5 lessons), lesson materials, and travel."
        },
        {
          title: "Location",
          content:
            "Offering private in-home lessons only. Travel is limited to a 30 minute drive radius (15-20 miles) from my home in Port Jefferson."
        },
        {
          title: "Holidays and Canceled/Missed Lessons",
          content:
            "There are no fee adjustments for missed lessons. However, makeup lessons can often be scheduled if given advance notice. Months with less than 3 lessons due to holidays or teacher cancellations with have reduced tuition rates."
        },
        {
          title: "COVID-19 Protocol",
          content:
            "Masks optional if the student is fully vaccinated. I am currently fully vaccinated (with booster)."
        },
      ]
    };
  }

  render() {
    return (
      <section id="lessons" className="about-mf  paralax-mf footer-paralax bg-image route"
        style={{ backgroundImage: "url(" + imageOverlay + ")" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-5" />
                  <div className="col-md-7">
                    <div className="title-box-2">
                      <h5 className="title-left">Lessons</h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="tuition-rates">
                      <h4>Tuition Rates</h4>
                      <p className="lead">Monthly rates. 3-5 lessons per month.</p>
                      <div>
                        {this.state.tuition_rates.map((skill, i) => {
                          return (
                            <React.Fragment key={i}>
                              <p style={{"fontSize": "16pt", "margin": "1em 0 0 0"}} className="lead">
                                {skill.minutes}-minute lessons: <b>${skill.cost} / mo. </b>
                              </p>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="about-me pt-4 pt-md-0">
                      {this.state.additional_info.map((content, i) => {
                        return (
                          <React.Fragment key={i}>
                            <h5>{content.title}</h5>
                            <p className="lead">
                              {content.content}
                            </p>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Tuition;
