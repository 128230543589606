import React from "react";
import imageOverlay from "../img/coverphoto.jpg";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      about_me: [
        {
          id: "first-p-about",
          content:
            "Kevin Hazelton was born in South Korea and was adopted at the ripe old age of one. Five years passed until he was able to find the piano bench, hop on, and try out what was soon to become his favorite instrument in the world. Throughout highschool, Kevin performed in various settings such as NYSSMA, Saint Rose Summer Jazz Camp, and classical recitals held by his teacher. When he reached senior year of highschool, he decided to apply to Berklee College of Music where he was accepted for piano and composition. He trained there for two years before deciding that there was more he wanted to learn outside of music. He transferred to Stony Brook University where he currently attends school. While he is no longer pursuing music through college, he still keeps his musical side active through piano compositions that he uploads online and practicing to expand his classical repertoire."
        },
        {
          id: "second-p-about",
          content:
            "Kevin is an attentive teacher who tailors his teaching material towards the student’s goals. He incorporates his background in classical and jazz theory from Berklee with his experience and love for all types of genres. He is most experienced in popular, new age, and classical music. Special activities may involve sing-a-longs with younger students and composition instruction with older students. Students are encouraged to suggest their own material and Kevin will adapt to the student’s interests while maintaining a skill level appropriate to their current abilities. Lessons can be more relaxed for students who are wishing to dip their toes, or more intense for those looking to progress at a faster rate."
        },
        {
          id: "third-p-about",
          content:
            "When Kevin is not practicing, he enjoys watching movies and originally was planning on being a film composer before transferring. Some of his favorite artists are Joe Hisaishi, Hiromi Uehara, and Ryuichi Sakamoto."
        }
      ]
    };
  }

  render() {
    return (
      <section id="about" className="about-mf paralax-mf footer-paralax bg-image route"
        style={{ backgroundImage: "url(" + imageOverlay + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-5" />
                  <div className="col-md-7">
                    <div className="title-box-2">
                      <h5 className="title-left">About Me</h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div
                      className="about-img"
                      style={{ textAlign: "center" }}
                    >
                      <img
                        id="headshot-img"
                        alt="A headshot of Kevin Hazelton"
                        src="myImage.png"
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="about-me pt-4 pt-md-0">
                      {this.state.about_me.map(content => {
                        return (
                          <p className="lead" key={content.id}>
                            {content.content}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
